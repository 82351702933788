<template>
  <div>
    <!-- 上半部 -->
    <div class="row mb-1 align-items-center">
      <!-- 按鈕 -->
      <div class="col-md-6 col-12 mb-1">
        <!-- 進階搜尋 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
      </div>
      <!-- 訊息 -->
      <div class="col-md-6 col-12 mb-1 fw-bolder">
        <!-- 搜尋區間 -->
        <p v-if="recordAdvancedSearchData.timeRange.switch">
          搜尋區間:
          <span
            v-if="
              recordAdvancedSearchData.timeRange.startTime &&
              recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
              recordAdvancedSearchData.timeRange.endTime
            }}</span
          >
          <span
            v-if="
              recordAdvancedSearchData.timeRange.startTime &&
              !recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
          >
          <span
            v-if="
              !recordAdvancedSearchData.timeRange.startTime &&
              recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
          >
        </p>
        <p>
          搜尋條件:
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect &&
              recordAdvancedSearchData.singleSelect.filterSender
            "
          >
            發送者: {{ recordAdvancedSearchData.singleSelect.filterSender }}
          </span>
        </p>
      </div>
    </div>
    <!-- dataTable -->
    <div class="tw-container">
      <div class="management-datatable">
        <DataTable
          class="p-datatable-sm"
          :scrollHeight="`${scrollHeight}px`"
          :value="lineNotifyHistoriesList"
          dataKey="id"
          :loading="dataTabelLoading"
          :rowHover="true"
          :paginator="true"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rows="20"
          :rowsPerPageOptions="[20, 50, 100]"
          :scrollable="true"
          currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
          v-model:filters="searchGlobal"
          filterDisplay="menue"
          stateStorage="local"
          stateKey="dt-state-pushinghistories-local"
          sortField="id"
          :sortOrder="-1"
          v-model:selection="selectItems"
        >
          <template #header>
            <div class="d-flex align-items-center">
              <input
                class="form-control me-2"
                v-model="searchGlobal['global'].value"
                placeholder="表格搜尋: 編號，訊息，時間"
                style="max-width: 99%"
              />
            </div>
          </template>
          <!-- 編號 -->
          <Column
            field="id"
            header="編號"
            sortable
            style="max-width: 80px; min-width: 80px"
          >
          </Column>
          <!-- 發布 -->
          <Column
            field=""
            header="發布"
            style="max-width: 120px; min-width: 120px"
          >
            <template #body="{ data }">
              <p class="fw-bolder">
                {{ data.release }}<i class="bi bi-reply-all-fill"></i>
              </p>
              <p class="text-secondary">{{ data.action }}</p>
            </template>
          </Column>
          <!-- 狀態 -->
          <Column
            field="success"
            header="狀態"
            sortable
            style="min-width: 80px; max-width: 80px"
          >
            <template #body="{ data }">
              <span
                class="fw-bolder"
                :class="
                  data.success === '成功' ? 'text-success' : 'text-danger'
                "
              >
                {{ data.success }}
              </span>
            </template>
          </Column>
          <!-- 來自 -->
          <Column header="來自" style="max-width: 150px">
            <template #body="{ data }">
              <div
                class="spinner-border spinner-border-sm"
                role="status"
                v-if="!data.from"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <p :class="`text-${data.from[1]}`" v-else>
                {{ data.from[0] }}
              </p>
            </template>
          </Column>
          <!-- 發送給 -->
          <Column header="發送給" style="max-width: 150px">
            <template #body="{ data }">
              <span>
                <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                  v-if="!data.to"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <p :class="`text-${data.to[1]}`" v-else>{{ data.to[0] }}</p>
              </span>
            </template>
          </Column>
          <!-- 訊息 -->
          <Column field="message" header="訊息" style="min-width: 200px">
            <template #body="{ data }">
              <p class="text-break">{{ data.message }}</p>
            </template>
          </Column>
          <!-- 時間 -->
          <Column
            field="createTime"
            header="時間"
            sortable
            style="min-width: 110px; max-width: 180px"
          >
            <template #body="{ data }"> {{ data.createTime }} </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';

export default {
  components: {DataTable, Column, ColumnGroup, Row},
  data() {
    return {
      // modal
      advancedSearchModal: {},
      // data
      serverToken: '',
      storeId: 0,
      storeOwnerId: 0,
      physicalUserData: [],
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      lineNotifyHistoriesList: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 選擇到的物件
      selectItems: [],
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        singleSelect: {
          filterSender: "自己",
        },
      },
      recordAdvancedSearchData: {},
      singleSelectsArray: [],
    }
  },
  created() {
    this.initialization()
    this.getStoreInfo()
  },
  mounted() {
    this.createModals(['advancedSearchModal'])
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-pushinghistories-local')
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-pushinghistories-local')

      this.singleSelectsArray = [
        {
          id: 'filterSender',
          key: '發送者',
          value: [
            {
              id: 'A1',
              value: '自己'
            },
            {
              id: 'A2',
              value: '系統'
            },
            {
              id: 'A3',
              value: '全部'
            },
          ]
        },
      ]
      // 進階搜尋
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(7, 'days').format('YYYY-MM-DD')
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      }
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getOwner}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeOwnerId = storeInfo.id
            vm.advancedSearch()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 進階搜尋
    advancedSearch() {
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      const query = {"columns":[{"columnName":"id","siteObjectType":60}],"distinct":true,"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":60},"operator":{"operator":"=","type":1,"value":this.storeId}}],"operator":1},"siteObjectType":60,"sqlCommandType":1}
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 60
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 60
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }

      // 單選條件
      switch (this.advancedSearchData.singleSelect.filterSender) {
        case "自己":
          query.group.groups.push({
            column: {
              columnName: "senderId",
              siteObjectType: 60,
            },
            operator: {
              operator: "false", // 這組 operator 是指 sender != null
              type: 4,
            },
          })
          break;
        case "系統":
          query.group.groups.push({
            column: {
              columnName: "senderId",
              siteObjectType: 60,
            },
            operator: {
              operator: "true", // 這組 operator 是指 sender = null
              type: 4,
            },
          })
          break;
          break;
      }
      this.getLineNotifyHistory(query)
    },
    // 取得推播歷程資料
    getLineNotifyHistory(query) {
      const vm = this
      const getLineNotifyHistoryApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getSender,getReceiver}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getLineNotifyHistoryApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const lineNotifyHistory = res.data[0].objects
            vm.lineNotifyHistoryMap(lineNotifyHistory)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 推播歷程資料整理
    lineNotifyHistoryMap(lineNotifyHistory) {
      this.physicalUserData = []
      this.lineNotifyHistoriesList = []
      lineNotifyHistory.forEach(item => {
        // 原資料
        const object = {
          action: item.action,
          class: item.class,
          createTime: this.$methods.moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
          from: false,
          id: item.id,
          message: item.message,
          receiver: item.receiver,
          receiverId: item.receiverId,
          release: '顧客',
          sender: item.sender,
          senderId: item.senderId,
          storeId: item.storeId,
          success: item.success,
          to: false
        }
        // 需特別處理
        // 發送
        switch(item.action) {
          case 'PRIVATE_MESSAGE':
            object.action = '私訊'
            break
          case 'CREATE_MERCHORDER':
            object.action = '建立商品訂單'
            break
          case 'CREATE_BILLHEADER':
            object.action = '建立帳單單頭'
            break
        }
        if (this.storeOwnerId === item.receiverId) object.release = '管理員'
        // 狀態 
        object.success = object.success ? '成功' : '失敗'
        // 來自
        if (item.senderId) {
          if (!item.sender.name) {
            if(!this.physicalUserData.includes(item.sender.userId)) this.physicalUserData.push(item.sender.userId)
          } else object.from = [item.sender.name, 'success']
        } else object.from = ['易團GO系統','warning']
        
        // 發送給
        if (!item.receiver.name) {
          if(!this.physicalUserData.includes(item.receiver.userId)) this.physicalUserData.push(item.receiver.userId)
        } else object.to = [item.receiver.name, 'success']
        this.lineNotifyHistoriesList.push(JSON.parse(JSON.stringify(object)))
      })
      this.dataTabelLoading = false
      this.advancedSearchModal.hide()
      this.$methods.switchLoading('hide')
      setTimeout(() => {
        this.getPhysicalUser(this.physicalUserData)
      },1)
    },
    // 取得實體用戶名稱
    getPhysicalUser(ids) {
      const vm = this
      const getPhysicalUserApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 1,
          ids: ids,
          methods: '{}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getPhysicalUserApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const physicalUser = res.data[0].objects
            vm.lineNotifyHistoriesList.forEach(item => {
              if (!item.to) {
                physicalUser.some(user => {
                  if(item.receiver.userId === user.id) {
                    item.to = [ user.name, 'secondary']
                    return true
                  }
                })
              }
              if (!item.from) {
                physicalUser.some(user => {
                  if(item.sender.userId === user.id) {
                    item.from = [user.name, 'secondary']
                    return true
                  }
                })
              }
            })
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    }
  }
}
</script>

